import "./App.css";
import { Routes, Route } from "react-router-dom";
import {
  Home,
  Search,
  BookList,
  AddBooksPage,
  LoginPage,
  Header,
} from "./components";
import { UserContext } from "./context";
import * as React from "react";

function App() {
  const [userId, setUserId] = React.useState("");
  const [access_token, setAccessToken] = React.useState("");

  return (
    <UserContext.Provider
      value={{ userId, setUserId, access_token, setAccessToken }}
    >
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/add-book" element={<AddBooksPage />} />
        <Route path="/book-list" element={<BookList />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
