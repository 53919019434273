import "../App.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../context";
import { get } from "axios";
function Header() {
  const production = process.env.NODE_ENV === "development" ? true : false;
  const baseURL = production
    ? "http://localhost:3000"
    : "https://api.buecherbleibe.xyz";
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  console.log(userId);
  const [profileImage, setProfileImage] = useState();
  console.log(baseURL + "/user-service/users/" + userId);
  useEffect(() => {
    get(baseURL + "/user-service/users/" + userId).then((res) => {
      if (res.data.code === 200) {
        console.log(res.data);
        setProfileImage(res.data.data.profileimage);
        console.log(res.data.data.profileimage);
        console.log(profileImage);
      }
    });
  }, []);
  return (
    <header aria-label="Site Header" class="bg-gray-900">
      <div class="mx-auto flex h-16 max-w-screen-xl items-center gap-8 px-4 sm:px-6 lg:px-8 ">
        <a class="block text-teal-600" onClick={() => navigate("/")}>
          <span class="sr-only">Home</span>

          <img
            src="https://cdn.discordapp.com/attachments/1034126648422969385/1042177508264661142/eg_book.png"
            alt="icon"
            class="h-8"
          />
        </a>

        <div class="flex flex-1 items-center justify-end md:justify-between">
          <nav aria-label="Site Nav" class="hidden md:block">
            <ul class="flex items-center gap-6 text-sm">
              <li>
                <a
                  class="text-gray-500 transition hover:text-gray-500/75"
                  onClick={() => navigate("/add-book")}
                >
                  Add Books
                </a>
              </li>

              <li>
                <a
                  class="text-gray-500 transition hover:text-gray-500/75"
                  onClick={() => navigate("/search")}
                >
                  Search
                </a>
              </li>
            </ul>
          </nav>

          <div class="flex items-center gap-4">
            {profileImage ? (
              <a class="block shrink-0">
                <span class="sr-only">Profile</span>
                <img
                  alt="Man"
                  src={profileImage}
                  class="h-10 w-10 rounded-full object-cover"
                />
              </a>
            ) : (
              <div class="sm:flex sm:gap-4">
                <a
                  class="block rounded-md bg-teal-600 px-5 py-2.5 text-sm font-medium text-white transition hover:bg-teal-700"
                  onClick={() => navigate("/login")}
                >
                  Login
                </a>
              </div>
            )}
            <button class="block rounded bg-gray-100 p-2.5 text-gray-600 transition hover:text-gray-600/75 md:hidden">
              <span class="sr-only">Toggle menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
