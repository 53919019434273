import { Add_Book } from "./index";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context";
import { get } from "axios";

function AddBooksPage() {
  const production = process.env.NODE_ENV === "development" ? true : false;
  const baseURL = production
    ? "http://localhost:3000"
    : "https://api.buecherbleibe.xyz";
  const { userId } = useContext(UserContext);
  console.log(userId);
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    if (!userId) {
      setLoggedIn(false);
    } else {
      get(baseURL + "/user-service/users/" + userId).then((res) => {
        if (res.data.code === 200) {
          if (res.data.data.permissions.includes("add_book")) {
            setLoggedIn(true);
          }
        }
      });
    }
  }, []);
  return (
    <>
      {loggedIn ? (
        <>
          <Add_Book />
        </>
      ) : (
        <>
          <h1>Not Authorized</h1>
        </>
      )}
    </>
  );
}

export default AddBooksPage;
