import "../App.css";
import { useState, useContext } from "react";
import axios from "axios";
import { UserContext } from "../context";

function Search() {
  const production = process.env.NODE_ENV === "development" ? true : false;
  const baseURL = production
    ? "http://localhost:3000"
    : "https://api.buecherbleibe.xyz";
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [noteWindow, setNoteWindow] = useState(false);
  const [note, setNote] = useState("");
  const { access_token } = useContext(UserContext);
  const [noteId, setNoteId] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();

    axios.get(`${baseURL}/search/${search}`).then((res) => {
      setSearchData(res.data);
      setIsLoading(false);
    });
  };
  const handleNoteSubmit = async (event) => {
    event.preventDefault();
    axios
      .post(`${baseURL}/notes/add?isbn=${noteId}`, {
        note,
        access_token,
      })
      .then((res) => {
        console.log(res);
        setNoteWindow(false);
        setNoteId("");
        setNote("");
        axios.get(`${baseURL}/search/${search}`).then((res) => {
          setSearchData(res.data);
          setIsLoading(false);
        });
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div class="relative">
          <label class="sr-only" for="search">
            text
          </label>

          <input
            class="w-full py-4 pl-3 pr-16 text-sm border-2 border-gray-200 rounded-lg"
            id="search"
            type="text"
            placeholder="Search Query"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />

          <button
            class="absolute p-2 text-white bg-blue-600 rounded-full -translate-y-1/2 top-1/2 right-4"
            type="subbmit"
          >
            <svg
              class="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </button>
        </div>
      </form>
      {!isLoading ? (
        <div>
          {searchData.map((book) => (
            <a class="overflow-hidden border border-gray-100 rounded-lg grid grid-cols-1 group sm:grid-cols-3">
              <div class="relative">
                <img
                  class="absolute inset-0 object-cover w-full h-full"
                  src={book.cover}
                  alt={book.name}
                />
              </div>

              <div class="p-8 sm:col-span-2">
                <h5 class="mt-4 font-bold">{book.name}</h5>
                {book.authors.map((author) => (
                  <p class="mt-2 text-sm text-gray-500">{author}</p>
                ))}

                <p class="mt-2 text-sm text-gray-500">ISBN: {book.isbn}</p>
                <p class="mt-2 text-sm text-gray-500">
                  Verlag: {book.publisher}
                </p>
                <p class="mt-2 text-sm text-gray-500">
                  Erscheinungsjahr: {book.date_published}
                </p>
                <p class="mt-2 text-sm text-gray-500">
                  Notitz:{" "}
                  {book.notes
                    ? book.notes.map((note) => {
                        return <p>{note.note}</p>;
                      })
                    : "Keine Notitz vorhanden"}
                </p>
                <button
                  class="mt-1 inline-block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
                  onClick={() => {
                    setNoteWindow(true);

                    setNoteId(book._id);
                  }}
                >
                  Add Note
                </button>
              </div>
            </a>
          ))}
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {noteWindow && (
        <section class=" mt-4 rounded-3xl shadow-2xl bg-gray-30">
          <div class="p-8 text-center sm:p-12">
            <p class="text-sm font-semibold uppercase tracking-widest text-pink-500">
              Add a note to The Book
            </p>
            <div class="flex justify-center">
              <div class="mb-3 xl:w-96">
                <form onSubmit={handleNoteSubmit}>
                  <textarea
                    onChange={(event) => setNote(event.target.value)}
                    value={note}
                    class="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
                    rows="3"
                    placeholder="Your message"
                  ></textarea>
                  <button
                    class="mt-2 inline-block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default Search;
