import { Footer, Start, Header } from "./index";

function Home() {
  return (
    <div>
      <Start />
      <Footer />
    </div>
  );
}

export default Home;
