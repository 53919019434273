import "../App.css";
import { useNavigate } from "react-router-dom";
function Start() {
  const navigate = useNavigate();
  return (
    <section class="text-white bg-gray-900">
      <div class="px-4 py-32 mx-auto max-w-screen-xl lg:h-screen lg:items-center lg:flex">
        <div class="max-w-3xl mx-auto text-center">
          <h1 class="text-3xl font-extrabold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
            Organize your Books.
          </h1>

          <p class="max-w-xl mx-auto mt-4 sm:leading-relaxed sm:text-xl">
            Das wird toll!
          </p>

          <div class="flex flex-wrap justify-center mt-8 gap-4">
            <a
              class="block w-full px-12 py-3 text-sm font-medium text-white bg-blue-600 border border-blue-600 rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-white focus:outline-none focus:ring"
              onClick={() => navigate("/add-book")}
            >
              Add Book
            </a>

            <a
              class="block w-full px-12 py-3 text-sm font-medium text-white border border-blue-600 rounded sm:w-auto hover:bg-blue-600 active:bg-blue-500 focus:outline-none focus:ring"
              onClick={() => navigate("/search")}
            >
              Search Books
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Start;
