import { createContext } from "react";

const UserContext = createContext({
  userId: "",
  setUserId: () => {},
  access_token: "",
  setAccessToken: () => {},
});

export default UserContext;
