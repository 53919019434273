import "../App.css";
import React from "react";

const BookList = React.forwardRef((props, ref) => (
  <div className="inline-grid grid-cols-4" ref={ref}>
    {props.books.map((book) => {
      return (
        <img
          src={book.base64}
          className="w-[51.5mm] h-[20.1mm] mr-1 ml-1"
          alt="book cover"
        />
      );
    })}
  </div>
));

export default BookList;
