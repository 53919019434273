import "../App.css";
import * as axios from "axios";
import { useState, useContext } from "react";
import { UserContext } from "../context";
import { useNavigate } from "react-router-dom";

function Login() {
  const production = process.env.NODE_ENV === "development" ? true : false;
  const baseURL = production
    ? "http://localhost:3000"
    : "https://api.buecherbleibe.xyz";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setUserId, setAccessToken, access_token } = useContext(UserContext);
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    axios
      .post(baseURL + "/user-service/login", {
        username,
        password,
      })
      .then((res) => {
        if (res.data.code === 200) {
          setUserId(res.data.userID);
          setAccessToken(res.data.access_token);

          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      });
  };

  return (
    <div class="h-screen flex">
      <div class="flex w-1/2 bg-gradient-to-tr from-blue-800 to-purple-700 i justify-around items-center">
        <div>
          <h1 class="text-white font-bold text-4xl font-sans">GoFinance</h1>
          <p class="text-white mt-1">
            The most popular peer to peer lending at SEA
          </p>
        </div>
      </div>
      <div class="flex w-1/2 justify-center items-center bg-white">
        <form class="bg-white" onSubmit={handleSubmit}>
          <h1 class="text-gray-800 font-bold text-2xl mb-1">Hello Again!</h1>
          <p class="text-sm font-normal text-gray-600 mb-7">Welcome Back</p>
          <div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
            <input
              class="pl-2 outline-none border-none"
              type="text"
              name="username"
              id="username"
              placeholder="Username"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </div>
          <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
            <input
              class="pl-2 outline-none border-none"
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <button
            type="submit"
            class="block w-full bg-indigo-600 mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
