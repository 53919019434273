import { Search_Site } from "./index";

function Search() {
  return (
    <div>
      <Search_Site />
    </div>
  );
}

export default Search;
