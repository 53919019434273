import "../App.css";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import BookList from "./book-list";
import { useReactToPrint } from "react-to-print";

const Add_Book = () => {
  const production = process.env.NODE_ENV === "development" ? true : false;
  const componentRef = useRef();
  const baseURL = production
    ? "http://localhost:3000"
    : "https://api.buecherbleibe.xyz";

  const [isbn, setIsbn] = useState("");
  const [success, setSuccess] = useState(false);
  const [manuel, setManuel] = useState(false);
  const [title, setTitle] = useState("");
  const [print, setPrint] = useState(false);
  const [books, setBooks] = useState([]);
  const [shelfMenu, setShelfMenu] = useState(false);
  const [shelf, setShelf] = useState("Wohnzimmer, A, 1");
  const [shelfID, setShelfID] = useState({
    floor: "og",
    room: "w",
    shelf: "a",
    number: 1,
  });
  const [alreadyInDB, setAlreadyInDB] = useState(false);

  const [shelfsLoaded, setShelfsLoaded] = useState(false);
  const [shelfs, setShelfs] = useState({});

  const loadShelfs = async () => {
    axios
      .get(baseURL + "/get-regale")
      .then((res) => {
        setShelfs(res.data);
        setShelfsLoaded(true);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    axios
      .post(`${baseURL}/add-book/${isbn}`, {
        body: {
          regal: shelfID,
        },
      })
      .then((res) => {
        if (res.data.code === 1) {
          setIsbn("");
          setSuccess(true);
          setTitle(res.data.title);
          setTimeout(() => {
            setTitle("");
            setSuccess(false);
          }, 6000);
        } else if (res.data.code === 2) {
          setIsbn("");
          setSuccess(false);
          setAlreadyInDB(true);

          setTimeout(() => {
            setAlreadyInDB(false);
          }, 6000);
        }
      })
      .catch((err) => console.error(err));
  };
  const handleClick = async (event) => {
    setManuel(true);
  };
  const handleSubbmit = async (event) => {
    event.preventDefault();

    const book = {
      name: event.target.title.value,
      isbn: event.target.isbn.value || null,
      subtitle: event.target.subtitle.value || " ",
      authors: event.target.authors.value || " ",
      publisher: event.target.publisher.value || " ",
    };

    axios
      .post(`${baseURL}/add-book-without-isbn`, {
        book,
        regal: shelfID,
      })
      .then((res) => {
        if (res.data.message === "Book added") {
          setSuccess(true);
          setTitle(res.data.title);
          setTimeout(() => {
            setTitle("");
            setSuccess(false);
          }, 6000);
        }
      });
  };

  const handlePrintButton = async () => {
    await getBooks();
    setPrint(true);

    setTimeout(() => {
      setPrint(false);
    }, 30000);
  };
  const getBooks = async () => {
    axios.get(baseURL + "/get-books-to-print").then((res) => {
      setBooks(res.data);
      Promise.resolve();
    });
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div class="relative ml-4 mr-4 mt-3">
          <label class="sr-only" for="search">
            text
          </label>

          <input
            class="w-full py-4 pl-3 pr-16 text-sm border-2 border-gray-200 rounded-lg"
            id="search"
            type="text"
            placeholder="Search Query"
            value={isbn}
            onChange={(event) => setIsbn(event.target.value)}
          />

          <button
            class="absolute p-2 text-white bg-blue-600 rounded-full -translate-y-1/2 top-1/2 right-4"
            type="subbmit"
          >
            <svg
              class="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
          </button>
        </div>
      </form>

      {success ? (
        <div
          class="flex items-center justify-between p-4 text-green-700 border rounded border-green-900/10 bg-green-50 ml-4"
          role="alert"
        >
          <strong class="text-sm font-medium">
            Book {title} added successfully!
          </strong>

          <button class="opacity-90" type="button">
            <span class="sr-only"> Close </span>

            <svg
              class="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ) : (
        <p className="ml-4">Enter ISBN to add Book to Database</p>
      )}
      {alreadyInDB && (
        <div
          class="flex items-center justify-between p-4 text-red-700 border rounded border-green-900/10 bg-yellow-50 ml-4"
          role="alert"
        >
          <strong class="text-sm font-medium">
            Das Buch ist bereits in der Datenbank!
          </strong>

          <button class="opacity-90" type="button">
            <span class="sr-only"> Close </span>

            <svg
              class="w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      )}
      <div class=" ml-4 mt-2 inline-flex items-stretch rounded-md border bg-white dark:border-gray-800 dark:bg-gray-900">
        <a class="rounded-l-md px-4 py-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-200">
          Momentanes Regal: {shelf}
        </a>

        <div class="relative">
          <button
            type="button"
            class="inline-flex h-full items-center justify-center rounded-r-md border-l border-gray-100 px-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700 dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-gray-200"
            onClick={() => {
              setShelfMenu(!shelfMenu);
              loadShelfs();
            }}
          >
            <span class="sr-only">Menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          {shelfsLoaded && shelfMenu && (
            <div
              class="absolute left-0 z-10 mt-4  w-56 origin-top-right rounded-md border border-gray-100 bg-white shadow-lg dark:border-gray-800 dark:bg-gray-900"
              role="menu"
            >
              <div class="flow-root py-2">
                <div class="-my-2 divide-y divide-gray-100 dark:divide-gray-800">
                  <div class="p-2">
                    <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                      Obergeschoss
                    </strong>
                    <div class="p-2">
                      <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                        Küche
                      </strong>

                      {shelfs.og.k.map((v) => {
                        return (
                          <a
                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                            role="menuitem"
                            onClick={() => {
                              setShelfID(v.id);
                              setShelf(v.name);
                              setShelfMenu(false);
                            }}
                          >
                            {v.name}
                          </a>
                        );
                      })}
                    </div>
                    <div class="p-2">
                      <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                        Schlafzimmer
                      </strong>

                      {shelfs.og.s.map((v) => {
                        return (
                          <a
                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                            role="menuitem"
                            onClick={() => {
                              setShelfID(v.id);
                              setShelf(v.name);
                              setShelfMenu(false);
                            }}
                          >
                            {v.name}
                          </a>
                        );
                      })}
                    </div>
                    <div class="p-2">
                      <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                        Wohnzimmer
                      </strong>

                      {shelfs.og.w.map((v) => {
                        return (
                          <a
                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                            role="menuitem"
                            onClick={() => {
                              setShelfID(v.id);
                              setShelf(v.name);
                              setShelfMenu(false);
                            }}
                          >
                            {v.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                  <div class="p-2">
                    <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                      Untergeschoss
                    </strong>
                    <div class="p-2">
                      <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                        Büro
                      </strong>

                      {shelfs.ug.b.map((v) => {
                        return (
                          <a
                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                            role="menuitem"
                            onClick={() => {
                              setShelfID(v.id);
                              setShelf(v.name);
                              setShelfMenu(false);
                            }}
                          >
                            {v.name}
                          </a>
                        );
                      })}
                    </div>
                    <div class="p-2">
                      <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                        Vorraum
                      </strong>

                      {shelfs.ug.v.map((v) => {
                        return (
                          <a
                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                            role="menuitem"
                            onClick={() => {
                              setShelfID(v.id);
                              setShelf(v.name);
                              setShelfMenu(false);
                            }}
                          >
                            {v.name}
                          </a>
                        );
                      })}
                    </div>
                    <div class="p-2">
                      <strong class="block p-2 text-xs font-medium uppercase text-gray-400 dark:text-gray-500">
                        Wintergarten
                      </strong>

                      {shelfs.ug.wg.map((v) => {
                        return (
                          <a
                            class="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                            role="menuitem"
                            onClick={() => {
                              setShelfID(v.id);
                              setShelf(v.name);
                              setShelfMenu(false);
                            }}
                          >
                            {v.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <button onClick={() => handleClick()}>
        <a class="relative inline-block text-sm font-medium text-indigo-600 group active:text-indigo-500 focus:outline-none focus:ring ml-4 mt-3">
          <span class="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-indigo-600 group-hover:translate-y-0.5 group-hover:translate-x-0.5 "></span>

          <span class="relative block px-8 py-3 bg-white border border-current ">
            Buch Manuell hinzufügen
          </span>
        </a>
      </button>

      {manuel ? (
        <div class="px-4 py-16 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="max-w-lg mx-auto text-center">
            <h1 class="text-2xl font-bold sm:text-3xl">
              Füge ein Buch Manuell hinzu
            </h1>

            <p class="mt-4 text-gray-500">
              Füge ein Buch manuell hinzu indem du das Formaluar ausfüllst. Wenn
              das Buch keine ISBN hat lasse das Feld einfach leer.
            </p>
          </div>

          <form
            onSubmit={(event) => handleSubbmit(event)}
            class="max-w-md mx-auto mt-8 mb-0 space-y-4"
          >
            <div>
              <label for="title" class="sr-only">
                Title
              </label>

              <div class="relative">
                <input
                  type="text"
                  id="title"
                  required={true}
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Enter Title"
                />
              </div>
            </div>

            <div>
              <label for="isbn" class="sr-only">
                ISBN
              </label>
              <div class="relative">
                <input
                  type="text"
                  id="isbn"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Enter ISBN or leave empty"
                />
              </div>
            </div>

            <div>
              <label for="subtitle" class="sr-only">
                Subtitle
              </label>

              <div class="relative">
                <input
                  type="text"
                  id="subtitle"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Enter Sub Title or leave empty"
                />
              </div>
            </div>

            <div>
              <label for="authors" class="sr-only">
                Authors
              </label>

              <div class="relative">
                <input
                  type="text"
                  id="authors"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Enter Authors seperate multiple authors with a comma (,)"
                />
              </div>
            </div>

            <div>
              <label for="publisher" class="sr-only">
                Publisher
              </label>

              <div class="relative">
                <input
                  type="text"
                  id="publisher"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Enter Publisher or leave empty"
                />
              </div>
            </div>

            <div class="flex items-center justify-between">
              <button
                type="submit"
                class="inline-block px-5 py-3  text-sm font-medium text-white bg-blue-500 rounded-lg"
              >
                Submit Book
              </button>

              <p class="text-sm text-gray-500">
                <a class="underline" onClick={() => setManuel(false)}>
                  Cancel
                </a>
              </p>
            </div>
          </form>
        </div>
      ) : (
        <p className="ml-4">Click on the Button to enter a book manuel</p>
      )}

      {print ? (
        <>
          <BookList ref={componentRef} books={books} />
          <button onClick={handlePrint}>
            <a class="relative inline-block group focus:outline-none focus:ring">
              <span class="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-yellow-300 group-hover:translate-y-1.5 group-hover:translate-x-1.5"></span>

              <span class=" relative inline-block px-8 py-3 text-sm font-bold tracking-widest uppercase border-2 border-current">
                Label für Bücher drucken
              </span>
            </a>
          </button>
        </>
      ) : (
        <button onClick={handlePrintButton}>
          <a class="ml-4 relative inline-block group focus:outline-none focus:ring">
            <span class="absolute inset-0 transition-transform translate-x-0 translate-y-0 bg-yellow-300 group-hover:translate-y-1.5 group-hover:translate-x-1.5"></span>

            <span class="relative inline-block px-8 py-3 text-sm font-bold tracking-widest uppercase border-2 border-current">
              Label für Bücher drucken
            </span>
          </a>
        </button>
      )}
    </div>
  );
};

export default Add_Book;
